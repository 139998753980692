import React from 'react';
import { Link } from 'gatsby';
import Image from 'gatsby-image';

import { Icon } from './Icon';
import allTopics from '../../contents/topics.json';

export const ArticlesList = ({ edges }) => (
  <section id="articles" className="section">
    <div className="container">
      <div className="box is-green">
        <h1 className="title is-spaced">Articles</h1>
        <h2 className="subtitle is-4">
          I feel ecstatic about sharing what I learn to give back to the community helping our industry improve.
        </h2>
        {edges.map(({ node: post }) => (
          <React.Fragment key={post.id}>
            <hr />
            <Link to={post.fields.slug}>
              <div className="columns is-vcentered">
                <div className="column is-4">
                  <Image fluid={post.frontmatter.image.childImageSharp.fluid} />
                </div>
                <div className="column is-8">
                  <h1 className="subtitle">{post.frontmatter.title}</h1>
                  <p className="has-text-justified has-text-grey" style={{ margin: '1rem 0' }}>{post.excerpt}</p>
                  <div className="columns is-size-7 has-text-grey">
                    <div className="column is-narrow">
                      <Icon name="date_range" className="small" /> <span style={{ verticalAlign: 'middle' }}>{post.frontmatter.datePublished}</span>
                    </div>
                    <div className="column is-narrow">
                      <Icon name="subject" className="small" /> <span style={{ verticalAlign: 'middle' }}>{post.frontmatter.topics.map(t => allTopics[t].title).join(', ')}</span>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </React.Fragment>
        ))}
      </div>
    </div>
  </section>
);
