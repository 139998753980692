import React from 'react';

export function Hero() {
  return (
    <section className="hero is-primary is-bold">
      <div className="hero-body">
        <div className="container">
          <div className="headlines has-text-centered">
            <h1 className="title is-spaced is-2">
              Progressive Software Developer
            </h1>
            <h2 className="subtitle is-4">
              Applying the power of computing to solve impactful problems and sharing the wisdom to enlighten software developers.
            </h2>
          </div>
        </div>
      </div>
    </section>
  );
}
