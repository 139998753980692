import React from 'react';
import { graphql } from 'gatsby';
import Image from 'gatsby-image';
import Helmet from 'react-helmet';

import { Layout } from '../components/layout';
import { Hero } from '../components/Hero';
import { ArticlesList } from '../components/ArticlesList';
import SocialCover from '../images/social-cover.png';

const IndexPage = ({ data: { site: { siteMetadata }, allMarkdownRemark, techIcons, companyLogos } }) => (
  <Layout pageClass="main-page">
    <Helmet>
      <meta property="og:url" content={siteMetadata.siteUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={siteMetadata.title} />
      <meta property="og:description" content={siteMetadata.description} />
      <meta property="og:image" content={siteMetadata.siteUrl + SocialCover} />
      <meta property="og:image:secure_url" content={siteMetadata.siteUrl + SocialCover} />
      <meta property="og:image:type" content="image/png" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="629" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content={siteMetadata.social.twitter} />
      <meta name="twitter:creator" content={siteMetadata.social.twitter} />
      <script type="application/ld+json">
        {JSON.stringify({
          '@context': 'http://schema.org',
          '@type': 'Person',
          '@id': siteMetadata.siteUrl + '/#person',
          name: siteMetadata.title,
          description: siteMetadata.description,
          url: siteMetadata.siteUrl,
          email: siteMetadata.email,
          gender: 'Male',
          logo: siteMetadata.siteUrl + '/icons/icon-512x512.png',
          sameAs: Object.keys(siteMetadata.social).map(k => siteMetadata.social[k])
        }, undefined, 2)}
      </script>
    </Helmet>
    <Hero />
    <ArticlesList edges={allMarkdownRemark.edges} />
    <section id="technologies" className="section">
      <div className="container">
        <div className="box">
          <h1 className="title is-spaced">Technologies</h1>
          <h2 className="subtitle is-4">
            I leverage modern engineering techniques and thoughtfully chose state of the art technologies.
          </h2>
          <hr />
          <h3 className="title is-5">Frontend</h3>
          <div className="content">
            <p>I heavily make use of Web technologies and extensively utilize the JavaScript and ClojureScript ecosystems. I build <strong>Progressive Web Apps</strong> and <strong>Statically Generated Websites</strong>. Wherever needed I build mobile and desktop apps using web technologies as well to get maximum reuse.</p>
            <ul>
              <li><strong>Languages:</strong> JavaScript, ClojureScript</li>
              <li><strong>Frameworks:</strong> React, Re-Frame, Apollo, Gatsby, Ionic, Electron</li>
              <li><strong>User Interface:</strong> Material Design, Bulma</li>
            </ul>
            <div className="tech-icons">
              <Image title="JavaScript" fixed={techIcons.edges.find(e => e.node.name === 'javascript').node.childImageSharp.fixed} />
              <Image title="ClojureScript" fixed={techIcons.edges.find(e => e.node.name === 'clojurescript').node.childImageSharp.fixed} />
              <Image title="React" fixed={techIcons.edges.find(e => e.node.name === 'react').node.childImageSharp.fixed} />
              <Image title="Ionic" fixed={techIcons.edges.find(e => e.node.name === 'ionic').node.childImageSharp.fixed} />
              <Image title="Gatsby" fixed={techIcons.edges.find(e => e.node.name === 'gatsby').node.childImageSharp.fixed} />
            </div>
          </div>
          <hr />
          <h3 className="title is-5">Backend</h3>
          <div className="content">
            <p>I primarily use Node.js and Clojure/JVM platforms for building Web APIs in <strong>Micro-Services</strong> and <strong>Serverless</strong> architecture and deploy them either as <strong>Containers as Service</strong> or <strong>Functions as Service</strong>. I make use of <strong>DevOps</strong> practices of <strong>Continuous Integration</strong> and <strong>Continuous Delivery</strong> as well.</p>
            <ul>
              <li><strong>Platforms:</strong> JavaScript/Node.js, Clojure/JVM</li>
              <li><strong>Databases:</strong> MongoDB, PostgreSQL, DynamoDB</li>
              <li><strong>Cloud Services:</strong> Amazon Web Services, Digital Ocean</li>
            </ul>
          </div>
          <div className="tech-icons">
            <Image title="Node" fixed={techIcons.edges.find(e => e.node.name === 'nodejs').node.childImageSharp.fixed} />
            <Image title="Clojure" fixed={techIcons.edges.find(e => e.node.name === 'clojure').node.childImageSharp.fixed} />
            <Image title="GraphQL" fixed={techIcons.edges.find(e => e.node.name === 'graphql').node.childImageSharp.fixed} />
            <Image title="PostgreSQL" fixed={techIcons.edges.find(e => e.node.name === 'postgresql').node.childImageSharp.fixed} />
            <Image title="MongoDB" fixed={techIcons.edges.find(e => e.node.name === 'mongodb').node.childImageSharp.fixed} />
          </div>
        </div>
      </div>
    </section>
    <section id="services" className="section">
      <div className="container">
        <div className="box">
          <h1 className="title is-spaced">Services</h1>
          <h2 className="subtitle is-4">
            I deliver neat solutions to complex problems which are joy to use.
          </h2>
          <hr />
          <h3 className="title is-spaced is-5">Development</h3>
          <h3 className="subtitle">Transform amazing ideas into delightful software</h3>
          <p className="has-text-justified">I provide high velocity full stack software development services from prototyping to deployment as an independent contractor. I carefully analyze each business domain and design a dependable solution that will stand the test of time. I pay special attention to software design aspects like compositionality and modularity to make sure codes are minimal and reusable. I will continuously improve and maintain the software - making sure it remains highly usable, scalable and secure over time, allowing you to focus more on your business.</p>
          <br />
          <a className="button is-primary is-rounded" href="mailto:faisal@faisalhasnain.com">Write Email</a>
          <hr />
          <h3 className="title is-spaced is-5">Consulting</h3>
          <h3 className="subtitle">Get insightful direction to reach highest potential</h3>
          <p className="has-text-justified">Technology should serve the purpose of business and not vice versa. I help you to smartly resolve technical challenges by using ideal technologies and techniques tailored for your use case. I can help in designing flexible systems to reach your business goals faster by aiming for higher developer productivity and better maintainability of the software. Get help on having sustainable technical architecture for meeting the growing business demands in most cost effective way possible.</p>
          <br />
          <a className="button is-primary is-rounded" href="https://calendly.com/faisalhasnain" target="_blank" rel="noopener noreferrer">Schedule Meeting</a>
        </div>
      </div>
    </section>
    <section id="experience" className="section">
      <div className="container">
        <div className="box">
          <h1 className="title is-spaced">Experience</h1>
          <h2 className="subtitle is-4">
            I have the pleasure to work with great people on awesome projects.
          </h2>
          <hr />
          <h3 className="title is-spaced is-5">Past Positions</h3>
          <h3 className="subtitle">Below are last few recent positions</h3>
          <div className="content">
            <ul>
              <li><strong>Lead Software Engineer:</strong> Liana Technologies, Finland</li>
              <li><strong>Full Stack Cloud Engineer:</strong> Virtask, Netherlands</li>
              <li><strong>Chief Technology Officer:</strong> Direct Dispatch, USA</li>
              <li><strong>Azure Cloud Developer:</strong> Namfus, Iceland</li>
            </ul>
          </div>
          <div className="tech-icons">
            <Image title="Liana Tech" fixed={companyLogos.edges.find(e => e.node.name === 'liana-tech').node.childImageSharp.fixed} />
            <Image title="Virtask" fixed={companyLogos.edges.find(e => e.node.name === 'virtask').node.childImageSharp.fixed} />
            <Image title="Direct Dispatch" fixed={companyLogos.edges.find(e => e.node.name === 'direct-dispatch').node.childImageSharp.fixed} />
            <Image title="WebDrvn" fixed={companyLogos.edges.find(e => e.node.name === 'webdrvn').node.childImageSharp.fixed} />
          </div>
        </div>
      </div>
    </section>
    <section id="education" className="section">
      <div className="container">
        <div className="box">
          <h1 className="title is-spaced">Education</h1>
          <h2 className="subtitle is-4">
            I have masters degree in computer science and have deep understanding of core concepts of computing.
          </h2>
          <hr />
          <h3 className="title is-spaced is-5">Online Courses</h3>
          <div className="content">
            <ul>
              <li><strong>Programming Languages:</strong> University of Washington</li>
              <li><strong>Software Engineering for SaaS:</strong> University of California, Berkeley</li>
              <li><strong>Startup Engineering:</strong> Stanford University</li>
              <li><strong>Machine Learning:</strong> Stanford University</li>
              <li><strong>Functional Programming Principles in Scala:</strong> École Polytechnique Fédérale de Lausanne</li>
              <li><strong>Principles of Reactive Programming:</strong> École Polytechnique Fédérale de Lausanne</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section id="about" className="section">
      <div className="container">
        <div className="box">
          <h1 className="title is-spaced">About</h1>
          <h2 className="subtitle is-4">
            My mission is to apply computing to positively impact people's lives and to advance the craft of software development.
          </h2>
          <hr />
          <p className="has-text-justified">
            I fell in love with the enormous power of programming computers while studying in 8th grade and ever since then I have been doing my passion for both fun and profit. I am a strong believer in the impact of computing to serve humanity and making this world a better place. I have excessive desire to attain excellence in software development - continuously exploring and learning new ideas. I am super blessed to have world's most sincere wife as my soulmate who is the driving force behind everything in my life. I highly value truthfulness and generosity in people. I consider simplicity, practicality and elegance while making choices in life. Besides technology I feel ecstatic about people, history, literature, design, architecture & equestrian sports.
          </p>
        </div>
      </div>
    </section>
  </Layout>
);

export const query = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
        title
        description
        social {
          twitter
          linkedin
          github
        }
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___datePublished], order: DESC }) {
      edges {
        node {
          id
          frontmatter {
            title
            author
            datePublished(formatString: "DD MMMM, YYYY")
            topics
            image {
              childImageSharp {
                fluid(maxWidth: 350) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          excerpt(pruneLength: 350)
          fields {
            slug
          }
        }
      }
    }
    techIcons: allFile(filter: { relativeDirectory: { eq: "topic-icons" }, name: { in: ["apollo-graphql", "clojurescript", "clojure", "gatsby", "graphql", "javascript", "mongodb", "nodejs", "postgresql", "react", "ionic"]} }) {
      edges {
        node {
          name
          childImageSharp {
            fixed(height: 100, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
    companyLogos: allFile(filter: { relativeDirectory: { eq: "company-logos" }, name: { in: ["liana-tech", "virtask", "direct-dispatch", "webdrvn"]} }) {
      edges {
        node {
          name
          childImageSharp {
            fixed(height: 74, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`

export default IndexPage;
